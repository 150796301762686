import siteHeader from './components/site-header/site-header';
import effects from './utilities/effects';

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM fully loaded and parsed');
  siteHeader();
  getComponents();
  effects();
});

function getComponents() {
  // Look for interactive map dom element,
  if (document.getElementById('interactiveMap')) {
    import(
      /* webpackChunkName: "interactiveMap" */ './components/interactiveMap/interactiveMap'
    )
      .then((module) => {
        module.default(window.interactiveMapState);
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the interactive map component \n' +
            error.message,
        );
      });
  }

  // Look for video overlay dom element
  if (document.getElementById('videoOverlayContainer')) {
    import(
      /* webpackChunkName: "videoOverlay" */ './components/lightbox/video-overlay'
    )
      .then((module) => {
        module.default();
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the video overlay component \n' +
            error.message,
        );
      });
  }

  // Look for the content overlay dom element
  if (document.querySelector('.show-overlay[data-overlay-id]')) {
    import(
      /* webpackChunkName: "contentOverlay" */ './components/lightbox/content-overlay'
    )
      .then((module) => {
        module.default();
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the content overlay component \n' +
            error.message,
        );
      });
  }

  // Look for number counters dom element
  if (document.querySelector('.number-counter')) {
    import(
      /* webpackChunkName: "numberCounter" */ './components/number-counter/number-counter'
    )
      .then((module) => {
        module.default();
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the number counter component \n' +
            error.message,
        );
      });
  }

  // Look for splide carousels dom element
  if (document.querySelector('.splide')) {
    import(
      /* webpackChunkName: "carousel" */ './components/carousel/splide-carousel'
    )
      .then((module) => {
        module.default();
      })
      .catch((error) => {
        throw new Error(
          'An error occurred while loading the carousel component \n' +
            error.message,
        );
      });
  }
}

/**
 * Hot reload (can be removed by React and Vue users)
 */
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(console.error);
}
